




































import Vue from 'vue'
import Component from 'vue-class-component'
import {Getter, Mutation} from 'vuex-class'
import {Menu} from "@/store/modules/MenuModule";
import {MutationMethod} from "vuex";

@Component
export default class CoreDrawer extends Vue {
    @Getter('MenuModule/getMenus') menus!: Array<Menu>
    @Getter('MenuModule/getDrawer') drawer!: Array<Menu>
    @Mutation('MenuModule/setDrawer') setDrawer!: MutationMethod

    get navDrawer() {
        return this.drawer
    }

    set navDrawer(val) {
        this.setDrawer(val)
    }

    onClick(e: Event, menu: Menu) {
        e.stopPropagation()

        if (!menu.href) return

        this.$vuetify.goTo(menu.href.endsWith('!') ? 0 : menu.href)
    }
}
