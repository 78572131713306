<template>
    <div style="position:relative">
        <VueDaumMap
            :appKey="appKey"
            :center="center"
            :level="level"
            :draggable="draggable"
            :mapTypeId="mapTypeId"
            @load="onLoad"
            class="rounded-md"
            style="width:100%;height:100%;border:1px solid #999">
        </VueDaumMap>
        <div
            style="position:absolute;top:0;left:0;z-index:1000;width:100%;height:100%;background-color:transparent"
            @click="$event.stopPropagation()"
        ></div>
        <v-btn
            class="ma-2"
            fab
            small
            color="white"
            style="position:absolute;top:0;right:0;z-index:1010"
            href="http://kko.to/7ozE87FYB"
            target="_blank"
        >
            <v-icon>mdi-arrow-expand-all</v-icon>
        </v-btn>
        <v-btn
            class="ma-2"
            fab
            small
            color="white"
            style="position:absolute;top:3rem;right:0;z-index:1000"
            @click="zoomIn"
        >
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
            class="ma-2"
            fab
            small
            color="white"
            style="position:absolute;top:6rem;right:0;z-index:1000"
            @click="zoomOut"
        >
            <v-icon>mdi-minus</v-icon>
        </v-btn>
    </div>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import VueDaumMap from 'vue-daum-map'

@Component({
    components: {
        VueDaumMap
    }
})
export default class KakaoMap extends Vue {
    appKey = 'f7662ff01e76f57d88371b47574e80b0'
    center = {lat: 33.4725857279464, lng: 126.534991988865}
    level = 9
    draggable = false
    mapTypeId = VueDaumMap.MapTypeId.NORMAL
    libraries = []
    map = null

    onLoad(map) {
        this.map = map;
        const position = new kakao.maps.LatLng(this.center.lat, this.center.lng);
        const marker = new kakao.maps.Marker({position});
        marker.setMap(map);
    }

    zoomIn() {
        this.map.setLevel(this.map.getLevel() - 1);
    }

    zoomOut() {
        this.map.setLevel(this.map.getLevel() + 1);
    }
}
</script>
