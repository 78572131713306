



































import Vue from 'vue'
import Component from 'vue-class-component'
import KakaoMap from "@/components/KakaoMap.vue"

@Component({
    components: {
        KakaoMap
    }
})
export default class SectionPlace extends Vue {

}
