
























































































import Vue from 'vue'
import Component from 'vue-class-component'
import Calendar from '@/components/Calendar.vue'

@Component({
    components: {
        Calendar
    }
})
export default class SectionReservation extends Vue {
    notices = [
        '하루에 총 4회의 클래스가 있습니다.(오전 10:30~ / 오후 1:30~ / 오후 4:30~ / 오후 7:00~)',
        '100% 예약제로 운영되며 네이버 예약으로 예약이 가능합니다',
        '궁금하신 점이 있으시면 전화/문자로 편하게 문의주세요.'
    ]

    qnaItems = [
        {
            question: '향에 대해 잘 몰라요. 그래도 체험이 가능한가요?',
            answer: '네. 향에 대해 전혀 모르셔도 괜찮아요. 기초이론 및 실습 과정을 통해 배우면서 처음이라도 쉽고 즐겁게 체험할 수 있습니다.'
        },
        {
            question: '몇 명까지 체험이 가능한가요?',
            answer: '보통 1~4명까지 체험이 가능합니다.'
        },
        {
            question: '혼자와도 되나요?',
            answer: '네. 혼자도 체험이 가능합니다. 부담 갖지 말고 마음 편히 오세요~'
        },
        {
            question: '몇 살 이상 체험이 가능한가요?',
            answer: '클래스는 보통 2시간인데 너무 어리면 2시간이 길게 느껴질 수 있고 향을 계속 맡는 것이 피곤할 수 있습니다. 특별히 제한은 없지만 중학생 이상을 추천합니다.'
        },
        {
            question: '향수 만들기 체험을 외부에서 단체로 받을 수 있나요?',
            answer: '네. 단체인 경우 수업 내용/시간/장소/요금을 협의하여 외부에서도 체험이 가능합니다. 예를 들어 ㅇㅇㅇ 사무실, 20명, 08:00~09:00 향수 만들기 원데이 클래스를 신청하여 체험할 수 있습니다.'
        }
    ]
}
