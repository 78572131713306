

















import {Component, Vue} from 'vue-property-decorator';
import CoreAppBar from '@/components/core/AppBar.vue';
import CoreDrawer from '@/components/core/Drawer.vue';
import CoreFooter from '@/components/core/Footer.vue';
import SectionIntro from '@/components/section/Intro.vue';
import SectionClass from '@/components/section/Class.vue';
import SectionReservation from '@/components/section/Reservation.vue';
import SectionReview from '@/components/section/Review.vue';
import SectionPlace from '@/components/section/Place.vue';

@Component({
    components: {
        CoreAppBar,
        CoreDrawer,
        CoreFooter,
        SectionIntro,
        SectionClass,
        SectionReservation,
        SectionReview,
        SectionPlace
    },
})
export default class Home extends Vue {
}
