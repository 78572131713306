






































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Calendar, {ScheduleInfo} from "@/components/Calendar.vue"

@Component({
    components: {
        Calendar
    }
})
export default class Schedule extends Vue {
    count = 30
    schedules: ScheduleInfo[] = []
    snackbar = false
    text = ''
    color = ''
    fab = false
    dialog = false
    password = ''

    async mounted() {
        const res = await this.axios.get('https://ho72py72rf.execute-api.ap-northeast-2.amazonaws.com/v1/schedules')
        const enabledList = (res.data as ScheduleInfo[])
            .filter(item => item.enable)
            .map(item => `${item.date}_${item.sequence}`)

        const schedules = []
        for (let i = 0; i < this.count; i++) {
            const date = this.$moment().add(i + 1, 'days').format('yyyy-MM-DD')
            for (let j = 1; j <= 4; j++) {
                schedules.push({
                    date: date,
                    sequence: j,
                    enable: enabledList.includes(`${date}_${j}`)
                });
            }
        }
        this.schedules = schedules
    }

    getLabel(schedule: ScheduleInfo): string {
        return `${this.$moment(schedule.date, 'yyyy-MM-DD').format('yyyy.MM.DD(ddd)')} ${schedule.sequence}회차`
    }

    async save() {
        const data = this.schedules.filter(item => item.enable)
        const res = await this.axios.post('https://ho72py72rf.execute-api.ap-northeast-2.amazonaws.com/v1/schedules', {
            password: this.password,
            schedules: data
        })
        this.dialog = false
        if (res.data.status === 200) {
            this.text = '저장성공'
            this.color = 'success'
            this.snackbar = true
        } else {
            this.text = '저장실패'
            this.color = 'error'
            this.snackbar = true
        }
    }

    onScroll() {
        const offsetTop = window.pageYOffset || document.documentElement.scrollTop
        this.fab = offsetTop > 160
    }

    toTop() {
        this.$vuetify.goTo(0)
    }
}
