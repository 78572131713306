import Vue from 'vue'
import Vuex from 'vuex'
import MenuModule from '@/store/modules/MenuModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    MenuModule
  }
})
