<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark
        >
            <div class="d-flex align-center">
                메모아 계산기 &ndash; 알콜
            </div>
        </v-app-bar>

        <v-main>
            <v-col cols="12" sm="6" md="3">
                <div v-if="defaultMode">
                    <v-radio-group v-model="type" row>
                        <v-radio label="알콜 95%" :value="0.95" @change="calc"></v-radio>
                        <v-radio label="알콜 99%" :value="0.99" @change="calc"></v-radio>
                    </v-radio-group>

                    <v-text-field
                        label="총용량(ml)"
                        outlined
                        type="number"
                        step="100"
                        v-model="amount"
                        @input="calc"
                    ></v-text-field>

                    <v-text-field
                        label="알콜 함유량(%)"
                        outlined
                        type="number"
                        v-model="percent"
                        @input="calc"
                    ></v-text-field>

                    <v-btn class="mb-8" @click="changeMode">
                        <v-icon>mdi-arrow-up-down</v-icon>
                    </v-btn>

                    <v-card>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>정제수 무게:</v-list-item-content>
                                <v-list-item-content class="align-end">{{ water | num }}g</v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>알콜 무게:</v-list-item-content>
                                <v-list-item-content class="align-end">{{ alcoholG | num }}g ({{ alcoholMl | num }}ml)
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </div>

                <div v-if="!defaultMode">
                    <v-radio-group v-model="type" row>
                        <v-radio label="알콜 95%" :value="0.95" @change="calc2"></v-radio>
                        <v-radio label="알콜 99%" :value="0.99" @change="calc2"></v-radio>
                    </v-radio-group>

                    <v-text-field
                        label="정제수 무게(g)"
                        outlined
                        type="number"
                        v-model="water"
                        @input="calc2"
                        step="0.1"
                    ></v-text-field>

                    <v-text-field
                        label="알콜 무게(g)"
                        outlined
                        type="number"
                        v-model="alcoholG"
                        @input="calc2"
                        step="0.1"
                    ></v-text-field>

                    <v-btn class="mb-8" @click="changeMode">
                        <v-icon>mdi-arrow-up-down</v-icon>
                    </v-btn>

                    <v-card>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>총용량:</v-list-item-content>
                                <v-list-item-content class="align-end">{{ amount | num }}ml</v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>알콜 함유량:</v-list-item-content>
                                <v-list-item-content class="align-end">{{ percent | num }}% ({{ alcoholMl | num }}ml)
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </div>

            </v-col>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'AlcoholCalc',

    data: () => ({
        defaultMode: true,
        type: 0.95,
        percent: 70,
        amount: 100,
        alcoholMl: 0,
        alcoholG: 0,
        water: 0
    }),
    created() {
        this.calc()
    },
    methods: {
        changeMode() {
            this.defaultMode = !this.defaultMode;
            if (this.defaultMode) {
                this.calc();
            } else {
                this.calc2();
            }
        },
        calc() {
            this.$nextTick(() => {
                const percent = Number(this.percent)
                const amount = Number(this.amount)
                const pureAlcoholMl = percent * amount / (0.211 * percent + 78.9)
                this.alcoholG = this.num((0.789 * pureAlcoholMl) + (pureAlcoholMl / this.type * (1 - this.type)))
                this.alcoholMl = this.num(this.alcoholG / (1 - 0.211 * this.type))
                this.water = this.num(amount - (pureAlcoholMl / this.type))
            })
        },
        calc2() {
            this.$nextTick(() => {
                const alcoholG = Number(this.alcoholG)
                const water = Number(this.water)
                this.alcoholMl = alcoholG / (1 - 0.211 * this.type)
                this.amount = this.num(this.alcoholMl + water)
                this.percent = this.num((this.alcoholMl * this.type * 0.789) / (alcoholG + water) * 100)
            })
        },
        num(val) {
            return Number(Number(val).toFixed(1))
        }
    },
    filters: {
        num(val) {
            return Number(val).toFixed(1)
        }
    }
}
</script>
