













































































































import {Component, Prop, Vue} from 'vue-property-decorator';

export interface ScheduleInfo {
    date: string;
    sequence: number;
    enable: boolean;
}

export interface EventInfo {
    name: string;
    details: string;
    start: Date;
    end: Date;
    color: string;
    timed: boolean;
}

@Component
export default class Calendar extends Vue {
    focus = ''
    events: EventInfo[] = []
    weekday = [0, 1, 2, 3, 4, 5, 6]
    selectedEvent = {}
    selectedElement = null
    selectedOpen = false
    @Prop() schedules!: ScheduleInfo[]
    list: ScheduleInfo[] = []
    loading = true

    async mounted() {
        if (!this.schedules) {
            const res = await this.axios.get('https://ho72py72rf.execute-api.ap-northeast-2.amazonaws.com/v1/schedules')
            this.list = res.data
        } else {
            this.list = this.schedules
        }
        this.loading = false
    }

    convert(schedules: ScheduleInfo[]): EventInfo[] {
        if (!schedules) return [];

        return schedules
            .filter(item => item.enable)
            .map(item => {
                let startTime = '';
                let endTime = '';
                let color = '';
                if (item.sequence === 1) {
                    startTime = '10:30';
                    endTime = '12:30';
                    color = 'orange';
                } else if (item.sequence === 2) {
                    startTime = '13:30';
                    endTime = '15:30';
                    color = 'green';
                } else if (item.sequence === 3) {
                    startTime = '16:30';
                    endTime = '18:30';
                    color = 'blue';
                } else if (item.sequence === 4) {
                    startTime = '19:00';
                    endTime = '21:00';
                    color = 'purple';
                }
                return {
                    name: `${item.sequence}회차 예약가능`,
                    details: `${this.$moment(item.date, 'yyyy-MM-DD').format('yyyy.MM.DD(ddd)')} ${startTime}~${endTime}`,
                    start: this.$moment(`${item.date}T${startTime}`, 'yyyy-MM-DDTHH:mm:ss').toDate(),
                    end: this.$moment(`${item.date}T${endTime}`, 'yyyy-MM-DDTHH:mm').toDate(),
                    color,
                    timed: false,
                }
            })
    }

    getEventColor(event: EventInfo) {
        return event.color
    }

    // @ts-ignore
    showEvent({nativeEvent, event}) {
        const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            setTimeout(() => {
                this.selectedOpen = true
            }, 10)
        }

        if (this.selectedOpen) {
            this.selectedOpen = false
            setTimeout(open, 10)
        } else {
            open()
        }

        nativeEvent.stopPropagation()
    }
}
