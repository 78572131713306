import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// @ts-ignore
import VueMoment from 'vue-moment'
import moment from 'moment'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

moment.locale('ko')
Vue.use(VueMoment, {
  moment,
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
