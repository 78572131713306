




































































































































import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class SectionIntro extends Vue {
    instgrmItems = new Array(20).fill({})

    async created() {
        await this.initInstgrm()
    }

    async initInstgrm() {
        const res = await this.axios.get('https://ho72py72rf.execute-api.ap-northeast-2.amazonaws.com/v1/instagram/media')
        this.instgrmItems = res.data.body.data.splice(0, 20)
    }

    open(url: string) {
        window.open(url)
    }
}
