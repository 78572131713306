import {Module, Mutation, VuexModule} from 'vuex-module-decorators'

export interface Menu {
  name: string;
  href?: string;
}

@Module({namespaced: true})
export default class MenuModule extends VuexModule {
  drawer = false
  menus: Menu[] = [
    {
      name: '소개',
      href: '#!',
    },
    {
      name: '클래스 안내',
      href: '#class',
    },
    {
      name: '예약/문의',
      href: '#reservation',
    },
    {
      name: '후기',
      href: '#review',
    },
    {
      name: '오시는 길',
      href: '#place',
    },
  ]

  @Mutation
  setDrawer(drawer: boolean) {
    this.drawer = drawer
  }

  @Mutation
  toggleDrawer() {
    this.drawer = !this.drawer
  }

  get getMenus(): Menu[] {
    return this.menus
  }

  get getDrawer(): boolean {
    return this.drawer
  }

}
