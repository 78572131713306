






















































import Vue from 'vue'
import Component from 'vue-class-component'
import {Getter, Mutation} from 'vuex-class'
import {Menu} from "@/store/modules/MenuModule";
import {MutationMethod} from "vuex";

@Component
export default class CoreAppBar extends Vue {
    @Getter('MenuModule/getMenus') menus!: Array<Menu>
    @Mutation('MenuModule/toggleDrawer') toggleDrawer!: MutationMethod

    onClick(e: Event, menu: Menu) {
        e.stopPropagation()

        if (!menu.href) return

        this.$vuetify.goTo(menu.href.endsWith('!') ? 0 : menu.href)
    }
}
