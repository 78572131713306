





























































































































import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class SectionClass extends Vue {
    classItems = [
        {
            color: 'primary',
            icon: 'mdi-bottle-soda-outline',
            src: 'https://img.memoire.co.kr/memoire/class1.jpg',
            title: '향수 원데이 클래스',
            desc: '케미컬 향료와 베이스 향료를 이용한<br>나만의 향수 만들기 50ml<br>이론 + 향료 스멜링 40분 / <span class="text-no-wrap">향연구 80분</span>',
            time: '2시간',
            price: '50,000',
            priceDesc: '',
            many: '1~4명',
            info: '인기',
            blogName: '블로그 체험 보기',
            blog: 'https://blog.naver.com/PostList.nhn?blogId=alecalec123&from=postList&categoryNo=7',
            url: 'https://booking.naver.com/booking/6/bizes/412003/items/3589670?area=bbt'
        },
        {
            color: 'success',
            icon: 'mdi-roman-numeral-1',
            src: 'https://img.memoire.co.kr/memoire/class2.jpg',
            title: '내추럴 원데이 클래스',
            desc: '천연향료을 이용한 향수 만들기 30ml<br>이론 + 향료 스멜링 40분 / <span class="text-no-wrap">향연구 80분</span>',
            time: '2시간',
            price: '75,000',
            priceDesc: '',
            many: '1~4명',
            info: '',
            blogName: '블로그 체험 보기',
            blog: 'https://blog.naver.com/PostList.nhn?blogId=alecalec123&from=postList&categoryNo=13',
            url: 'https://booking.naver.com/booking/6/bizes/412003/items/3593225?area=bbt'
        },
        {
            color: 'primary',
            icon: 'mdi-bottle-tonic-outline',
            src: 'https://img.memoire.co.kr/memoire/class3.jpg?v=1',
            title: '룸&패브릭 원데이 클래스',
            desc: '케미컬 향료와 천연향료를 이용한<br> 나만의 룸스프레이 100ml + <br>패브릭 스프레이 100ml 만들기<br>스멜링 40분 + 향 연구 80분',
            time: '2시간',
            price: '50,000',
            priceDesc: '',
            many: '1~4명',
            info: '',
            blogName: '블로그 체험 보기',
            blog: 'https://blog.naver.com/PostList.nhn?blogId=alecalec123&from=postList&categoryNo=15',
            url: 'https://booking.naver.com/booking/6/bizes/412003/items/3772216?area=bbt'
        },
        {
            color: 'primary',
            icon: 'mdi-bottle-tonic-outline',
            src: 'https://img.memoire.co.kr/memoire/class4.jpg',
            title: '로더랩 퍼퓸 디자이너',
            desc: '<span class="text-no-wrap">조향이론, 86가지 베이스향료 이용한 향연구</span><br>전문적인 조향과정을 체계적으로 교육<br>자격증 발급 후 강사 자격이 주어짐',
            time: '총 9회. 주 1회 3시간',
            price: '1,780,000',
            priceDesc: '수강료 150만원<br>교재&키트비용 20만원<br>자격증발급비용 8만원',
            many: '',
            info: 'NEW',
            blogName: '커리큘럼 자세히 보기',
            blog: 'https://blog.naver.com/alecalec123/222443870994',
            url: ''
        },
        {
            color: 'primary',
            icon: 'mdi-bottle-tonic-outline',
            src: '',
            title: '디퓨저 원데이 클래스',
            desc: '(준비중)',
            time: '1일 2시간',
            price: '',
            priceDesc: '',
            many: '',
            info: '',
            url: ''
        },
        {
            color: 'primary',
            icon: 'mdi-eyedropper',
            src: '',
            title: '조향사 트레이닝 클래스',
            desc: '(준비중)',
            time: '1일 2시간',
            price: '',
            priceDesc: '',
            many: '',
            info: '',
            url: ''
        },
        {
            color: 'orange darken-1',
            icon: 'mdi-briefcase-outline',
            src: '',
            title: '외부 출강',
            desc: '단체인 경우 외부 출강이 가능합니다.<br>수업 내용/시간/요금을 문의주세요.',
            time: '30분~1시간',
            price: '-',
            priceDesc: '',
            many: '10~30명',
            info: '',
            url: ''
        }
    ]
}
