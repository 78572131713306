


































































import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class SectionReview extends Vue {
    reviews = [
        {
            reviewer: 'Su*',
            content: '즐거운 경험이었어요~ 내가 원하는 향들을 골라도 결과물은 예상했던 향과 다르더라구요. 사실 그게 향수 만들기의 매력이 아닌가 싶어요. 이랬다 저랬다 많이도 고민하고 번복했는데 선생님께서 어떤향을 원하는지 정확히 캐치하시고 옆에서 지도해주신 덕에 맘에 드는 향수를 만들었어요~ 감사합니다 :)'
        },
        {
            reviewer: '형*',
            content: '"내가 만드는 향수는 추상적인 아이디어를 형상화한 것이 아니라, 바로 내가 있는 \'장소\'와 내가 선택한 \'소재\'로부터 출발한다. 내가 조작할 수 있는 향의 에센스들이 줄지어 서 있는 작업대를 앞에 두고, 결정적인 코드가 될 만한 단 하나 혹은 여러 향들을 선택한다는 것은 내가 앞으로 따라가야 할 길을 기약하는 것이다. 이렇듯 걱정과 선택의 순간은 늘 연장선상에 있어 왔다.\' - 나는 향수로 글을 쓴다, 장 끌로드 엘레나 63/216 \'백 마디 사랑의 말보다 한 순간의 향기가 사람의 마음을 사로잡을 수 있다.\'는 말이 진실일지도 모르겠습니다. 그동안 무감했던 냄새와 향들에 대해 느껴보고 생각해볼 수 있었던 시간이었네요. 덕분에 많이 배워갑니다! 세상의 모든 향을 다 맡아보거나 만나볼 수 없어도, 자기가 제일 좋아하는 향기를 발견하고 그것을 이따금씩 맡을 수 있다는 것, 그것만으로도 행복한 거겠죠? 아름다운 공간에서 좋은 추억과 시간을 선물해주셔서 감사합니다. 기회가 되면 그 때는 다른 누군가와 함께 (^^)찾아뵙고 싶네요.'
        },
        {
            reviewer: '석*',
            content: '정성 그 자체, 원데이 클래스지만 한 학기 동안 수업을 들은 느낌의 결과물을 얻고 돌아갑니다. 하나하나 체크해주시고 향수에 대한 지식이 없는 우리를 위해 다양한 종류의 향을 조합해주셔서 많이 배우는 시간이었습니다. 다시 한 번 감사드립니다.'
        },
        {
            reviewer: '정*',
            content: '친구와 함께 하는 여행에서 더 특별한 추억이 될 것 같아서 가벼운 마음으로 신청했었는데, 너무나 만족했어요! 향알못 초보자였는데 제가 모자라다는 느낌보다는 배려 받는 느낌이라 넘 좋았어요! 궁금한 거 다 물어봐도 진지하게 답해주시는ㅎㅎ 호스트 관여 없이 나름대로의 향조합을 하고 난 뒤에 수정해주시기도 하고 시중의 향수들과 재료자체도 다르기때문에, 미리 말해주신 것처럼 원하는 결과물이 나오지는 않지만(?) 미리 우려를 표하셨던 사항이라 오히려 만족하고 저만의 취향에 대해 알아가는 느낌이라 좋았어여. 더 공부되는 느낌이기도 하고여! 호스트님의 조향에 대한 애정이 듬뿍 느껴지는데, 요즘 진로에 대해 고민하던 중이었던지라 더 많은 것들을 얻고 온 것 같아요.'
        },
        {
            reviewer: '주*',
            content: '향수 만들기와 조향 체험해보기에 좋은 프로그램이에요! 생각보다 조향작업이 어려워서 만족하는 결과물이 나오진 않았지만,,, 향수만들기를 꼭 체험해보고 싶은 분들께는 추천해요 ! 정확한 자기만의 취향이 있으신 분들은 좋을 것 같은데 저는 기분따라, 상황따라 좋아하는게 달라서그런지 ,,,, ! 호스트는 친절하고 잘 챙겨주셨어요 :)'
        },
        {
            reviewer: 'J*',
            content: '코로나때문에 어디도 못가고있다가 기분전환 할 겸 신청해봤는데 너무 재밌었어요:) 몇개의 향을 테스트해보며 향을 취하고 내 것을 만들어 내는 과정이 어렵지만 향에 대해 지식이 쌓이고 더 집중하면서 맡을 수 있게 된 것 같아요. 많은 도움이 되었습니다. A~H까지 조합했던 향수들의 샘플도 챙겨주시고 너무감사합니다:) 혼자여행하는 분들께도 추천하며 연인 가족 친구 와 함께해도 정말 좋을 것 같아요!'
        },
        {
            reviewer: '미*',
            content: '전문가 선생님의 재밌는 이론 설명부터 끝나는 시간까지 흥미진진했어요. 가격대비 너무 큰 만족감을 받았어요. 저희 모녀는 다시 가고싶어요 강추!!!'
        },
        {
            reviewer: '진*',
            content: '기대보다 훨씬 재밌고 두 시간이 금방 지나갔어요. 최대한 저의 취향에 맞추면서도 균형을 잘 맞춰주셔서 향이 정말 만족스러워요♡ 친절하시고 정확하게 설명해주셔서 지인들이나 제주여행객들에게 자신있게 추천할 수 있는 체험이에요^^! 얼른 시간지나서 향수 뿌리고싶네요^^!!!'
        }
    ]
}
