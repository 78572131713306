import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import AlcoholCalc from '../components/admin/calc/AlcoholCalc.vue'
import NotFound from '../views/NotFound.vue'
import Schedule from "@/components/admin/schedule/Schedule.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/new',
    name: 'Home',
    component: Home
  },
  {
    path: '/calc/alcohol',
    name: 'AlcoholCalc',
    component: AlcoholCalc
  },
  {
    path: '/calc',
    redirect: '/calc/alcohol'
  },
  {
    path: '/calc/hand-sanitizer',
    redirect: '/calc/alcohol'
  },
  {
    path: '/admin/schedule',
    name: 'Schedule',
    component: Schedule
  },
  {path: '*', component: NotFound}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
